import { useEffect } from 'react';
import { GetStaticPaths, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { useSession } from 'next-auth/react';
import * as Sentry from '@sentry/nextjs';

import Config from '@web/config';

import { ErrorFrame } from '@web/components/error-frame/error-frame';

import Page from '@web/layouts/page';
import { GlobalContextProvider } from '@web/layouts/page/context';

import { appError } from '@web/services/errorhandler/appError';
import { fetchUmbracoData } from '@web/services/umbraco/fetch';
import { PageContext } from '@web/services/umbraco/fetchers/page-context';
import { fetchSSGRoutes } from '@web/services/umbraco/fetchers/ssgRoutes';
import { buildLinkForEdit, isAuthenticatedTemplate } from '@web/services/umbraco/helpers';
import { MappedRootData } from '@web/services/umbraco/queries/root.query';
import { normalizeSlug } from '@web/services/umbraco/route-mapping';
import { UmbracoErrorType } from '@web/services/umbraco/types/basic/ErrorTypes';

import {
	DocumentTypeMap,
	mapSlugToDocumentType,
	TemplateComponent,
	TemplateKey,
	TemplateMap,
	templates,
} from '@web/templates';

type BaseType<T extends TemplateKey = TemplateKey> = {
	preview: boolean;
	docType: T;
	rootData: MappedRootData;
	routeData: T extends keyof DocumentTypeMap ? DocumentTypeMap[T] : never;
	pageContext?: PageContext;
};

export function Index({ docType, routeData, preview, rootData, pageContext }: BaseType) {
	const { status } = useSession();

	useEffect(() => {
		Sentry.setExtra('preview', preview);
		Sentry.setExtra('docType', docType);

		Sentry.setTag('UmbracoDocument', buildLinkForEdit(routeData.id));

		Sentry.setExtra('rootNode', {
			id: rootData.id,
			name: rootData.name,
			url: rootData.url,
			contentType: rootData.contentType,
			updateDate: rootData.updateDate,
			writerId: rootData.writerId,
		});

		Sentry.setExtra('routeNode', {
			id: routeData.id,
			name: routeData.name,
			url: routeData.url,
			contentType: routeData.contentType,
			updateDate: routeData.updateDate,
			writerId: rootData.writerId,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- No reason to check all props here
	}, [docType, preview, rootData.contentType, rootData.id, routeData.id]);


	const Component: TemplateComponent<TemplateMap[typeof docType]> = templates[docType].component;

	if (Config.APP_ENV === 'local' && typeof window !== 'undefined') {
		window['_debug'] = {
			docType,
			routeData,
			rootData,
			pageContext,
		};
	}

	return (
		<GlobalContextProvider
			rootData={rootData}
			preview={preview}
			routeData={routeData as TemplateMap[TemplateKey]}
			pageContext={pageContext}
		>
			<Page>
				{(() => {
					if (isAuthenticatedTemplate(docType)) {
						if (status === 'unauthenticated') {
							return (
								<ErrorFrame errorType={UmbracoErrorType.Unauthorized} />
							);
						}

						if (status === 'loading') {
							return <div style={{ minHeight: '400px' }} />;
						}
					}

					return (
						<Component pageData={routeData as TemplateMap[TemplateKey]} />
					);
				})()}
			</Page>
		</GlobalContextProvider>
	);
}

export async function getStaticProps({
	preview = false,
	params,
}: GetStaticPropsContext): Promise<GetStaticPropsResult<BaseType>> {
	try {
		const slug = normalizeSlug(params?.slug);
		const slugMap = await mapSlugToDocumentType(slug, { preview });
		Sentry.setExtra('preview', preview);
		Sentry.setExtra('docType', slugMap.docType);
		Sentry.setExtra('slug', slug);

		const { rootData, routeData, pageContext } = await fetchUmbracoData(slugMap);

		// If there's no data from any of the data services
		if (!routeData) {
			return {
				revalidate: 60,
				notFound: true,
			};
		}

		Sentry.setTag('UmbracoDocument', buildLinkForEdit(routeData.id));

		Sentry.setExtra('rootNode', {
			id: rootData.id,
			name: rootData.name,
			url: rootData.url,
			contentType: rootData.contentType,
			updateDate: rootData.updateDate,
			writerId: rootData.writerId,
		});

		Sentry.setExtra('routeNode', {
			id: routeData.id,
			name: routeData.name,
			url: routeData.url,
			contentType: rootData.contentType,
			updateDate: routeData.updateDate,
			writerId: rootData.writerId,
		});

		// If we're here, it means there's data from root node as minimum and that route is considered a valid slug.
		return {
			props: {
				preview,
				docType: slugMap?.docType,
				rootData,
				routeData,
				pageContext,
			},
			revalidate: getRevalidateSetting(slugMap.docType),
		};
	} catch (err) {
		Sentry.setExtra('slugCatch', {
			error: err,
		});

		throw appError({ cause: err, message: 'Unable to render [[...slug]] route', info: { params, preview } });
	}
}

function getRevalidateSetting(doctype: string): boolean | number {
	if (doctype === 'eventPage') {
		return 60;
	}

	return false;
}

export const getStaticPaths: GetStaticPaths = async () => {
	const pages = await fetchSSGRoutes();

	const paths = pages.reduce((collector, page) => {
		if (page.url === '/404' || page.url === '/500') {
			return collector;
		}

		return [
			...collector,
			{
				params: {
					// Split the slug strings to arrays (as required by Next.js)
					slug: page?.url?.split('/').filter((p) => p),
				},
			},
		];
	}, [] as { params: { slug: string[] }; locale: string }[]);

	return {
		paths,
		fallback: 'blocking',
	};
};

export default Index;
